function onFetchInit(data) {
  let form = {
    ...data,
    homePage: data.homePage == '0' ? '1' : '0',
    mallPage: data.mallPage == '0' ? '1' : '0',
    scenicGuideState: data.scenicGuideState == '0' ? '1' : '0',
    shoppingCartState: data.shoppingCartState == '0' ? '1' : '0',
  }
  return {
    form,
  }
}

function onSubmit({ detail, form }) {
  return {
    ...detail,
    homePage: form.homePage == '0' ? '1' : '0',
    mallPage: form.mallPage == '0' ? '1' : '0',
    scenicGuideState: form.scenicGuideState == '0' ? '1' : '0',
    shoppingCartState: form.shoppingCartState == '0' ? '1' : '0',
  }
}

export default {
  type: 'cardForm',
  url: '/config/farmConfigHome/detail',
  edit: '/config/farmConfigHome/update',
  onFetchInit,
  onSubmit,
  data: [
    {
      form: [
        {
          name: '显示首页',
          type: 'switch',
          key: 'homePage',
          labelCol: { span: 3 },
          wrapperCol: { span: 20 },
        },
        {
          name: '显示商城',
          type: 'switch',
          key: 'mallPage',
          labelCol: { span: 3 },
          wrapperCol: { span: 20 },
        },
        {
          name: '显示购物车',
          type: 'switch',
          key: 'shoppingCartState',
          labelCol: { span: 3 },
          wrapperCol: { span: 20 },
        },
        {
          name: '显示景区导览',
          type: 'switch',
          key: 'scenicGuideState',
          labelCol: { span: 3 },
          wrapperCol: { span: 20 },
        },
        {
          type: 'text',
          value: '必须开启一个，不能同时关闭！',
          labelCol: { span: 3 },
          wrapperCol: { span: 20 },
          styles: {
            paddingLeft: '40px',
          },
        },
      ],
    },
  ],
}
