<script>
import { Tree, Input, Row, Col, Card, Select } from 'ant-design-vue'
import FormView from '../../../components/FormView'
import DrawerForm from '../../../components/DrawerForm'
import TableVIew from '../../../components/TableVIew'
import api from '@/command/api'
import PopButton from '../../../components/PopButton'
import { getAction, putAction } from '@/command/netTool'
const { Option } = Select

export default {
  name: 'baseInfo',
  data() {
    return {
      ...api.baseInfo.getState(),
      select: null,
      formData: {},
      inputValue: '',
      current_selection: null,
      isShowNum: null,
      isShowNumList: [],
    }
  },
  mounted() {
    getAction('/api/farmKingKongTemp/list').then((res) => {
      const { data } = res
      this.isShowNumList = data
      this.isShowNum = data.find((f) => f.status == '1')?.id || '1'
    })

    api.baseInfo.getTreeList.call(this, {
      url: '/api/config/farmConfigBase/list',
    })
  },
  methods: {
    getSelectUrl(data) {
      const fun = require('./FormData/' + data.key).default
      const obj = {
        update: this.update,
        del: this.del,
        create: this.create,
        getDetail: () => this.setSelect(this.select),
      }
      if (fun.render) {
        return fun.render(obj)
      } else {
        if (typeof fun == 'function') {
          return fun(obj)
        } else {
          return fun
        }
      }
    },
    getParams(params) {
      if (params) {
        let text = ''
        Object.keys(params).forEach((e) => {
          text += '&' + e + '=' + params[e]
        })
        return text
      } else {
        return ''
      }
    },
    async setSelect(data) {
      const select = this.getSelectUrl(data)
      const selectUrl = select.url
      this.onFetchInit = select.onFetchInit
      if (selectUrl) {
        api.baseInfo.getTreeDetail.call(this, {
          url: selectUrl + `?id=${data.id}` + this.getParams(select.params),
        })
      } else if (this.onFetchInit) {
        const net = await this.onFetchInit()
        this.treeTypeData = net.typeData
      }
      this.select = data
    },
    // 树形被选择
    onTreeSelectedBtn(selectkey, item) {
      const data = item.node.$options.propsData.dataRef
      this.current_selection = data.key
      this.setSelect(data)
    },
    onFormSuccess(form) {
      const select = this.getSelectUrl(this.select)
      const selectUrl = select.edit
      if (select.edit) {
        if (select.custom) {
          select.onSubmit && select.onSubmit({ form, detail: this.treeNetData })
        } else {
          try {
            api.baseInfo.edit.call(this, {
              url: selectUrl,
              params: select.onSubmit ? select.onSubmit({ form, detail: this.treeNetData }) : form,
            })
          } catch (e) {}
        }
      } else if (select.onSubmit) {
        select.onSubmit({ form, detail: this.treeNetData })
      }
    },
    onSearch(data) {
      this.inputValue = data.target.value
    },
    getTreeChildren(item) {
      return item.children.some((e) => {
        if (e.name.indexOf(this.inputValue) !== -1 || this.getTreeChildren(e)) {
          return true
        } else {
          return false
        }
      })
    },
    getTreeData() {
      if (this.inputValue == '') return this.treeData
      let data = []
      this.treeData.filter((e) => {
        if (e.name.indexOf(this.inputValue) !== -1 || this.getTreeChildren(e)) {
          if (e.childList) {
            e.childList.forEach((i) => {
              if (i.name.indexOf(this.inputValue) !== -1) {
                data.push(i)
              }
            })
          } else {
            data.push(e)
          }
          return true
        } else {
          return false
        }
      })
      let arr = data.map((e) => {
        return {
          ...e,
          key: e.code,
          title: e.name,
        }
      })
      return arr
    },
    renderTree() {
      return (
        <Col span={4}>
          <Card>
            <Input.Search onChange={this.onSearch} />
            {this.treeData.length > 0 && (
              <Tree
                autoExpandParent={true}
                defaultExpandAll={true}
                defaultExpandParent={true}
                treeData={this.getTreeData()}
                onSelect={this.onTreeSelectedBtn}
              />
            )}
          </Card>
        </Col>
      )
    },
    getFormData() {
      try {
        return this.getSelectUrl(this.select)
      } catch (error) {
        console.log('报错', error)
      }
    },
    getData(data) {
      return () => {
        return typeof data == 'function' ? data(this.treeNetData, this.treeDetail) : data
      }
    },
    renderForm(data) {
      return (
        <FormView
          ref={'_form'}
          layout="horizontal"
          cols={24}
          data={this.getData(data)}
          form={this.treeDetail}
          typeData={this.treeTypeData}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          onSuccess={this.onFormSuccess}
        />
      )
    },
    renderDrawerForm(data) {
      return (
        <DrawerForm
          data={this.getData(data)}
          ref={'_form'}
          onSuccess={this.onFormSuccess}
          formProps={{
            form: this.treeDetail,
            typeData: this.treeTypeData,
            labelCol: { span: 5 },
            wrapperCol: { span: 18 },
          }}
        />
      )
    },
    update(form) {
      const selectUrl = this.getSelectUrl(this.select).edit
      return api.baseInfo.edit.call(this, { url: selectUrl, params: form })
    },
    del(form) {
      const selectUrl = this.getSelectUrl(this.select).del
      return api.baseInfo.del.call(this, {
        url: selectUrl + `${form.id}`,
        params: form,
      })
    },
    create(form) {
      const selectUrl = this.getSelectUrl(this.select).create
      debugger
      return api.baseInfo.create.call(this, {
        url: selectUrl,
        params: form,
      })
    },
    renderTable(props, dataSource) {
      return (
        dataSource instanceof Array && (
          <TableVIew
            props={props}
            {...props}
            dataSource={dataSource}
            showRowSelect={false}
            showHeader={false}
            showPagination={false}
            scrollX={'auto'}
            styles={props.styles}
          />
        )
      )
    },
    renderView(view) {
      switch (view.type) {
        case 'form':
          return this.renderForm(view.data)
        case 'cardForm':
          return this.renderDrawerForm(view.data)
        case 'cardFormTable':
          return this.renderDrawerForm(view.data)
        case 'table':
          return this.renderTable(view.props, this.treeDetail)
        case 'tableGroup':
          return view.data.map((e) =>
            this.renderTable(
              e,
              Array.isArray(this.treeTypeData) ? [] : this.treeTypeData ? this.treeTypeData[e.key] || [] : []
            )
          )
        default:
          return this.renderForm(view.data)
      }
    },
    onSubmit() {
      this.$refs._form.sumbit()
    },
    onReset() {
      this.treeDetail = {}
    },
    renderFoot() {
      const button = [
        {
          name: '保存修改',
          type: 'primary',
          onClick: this.onSubmit,
          display: this.current_selection != 'travel_month_background',
        },
        {
          name: '重置',
          onClick: this.onReset,
          display: this.current_selection != 'travel_month_background',
        },
      ].filter((e) => e.display)
      return (
        <div class="base-info-foot">
          {button.map((e) => (
            <PopButton item={e} onClick={e.onClick} />
          ))}
        </div>
      )
    },
    getShowFoot() {
      if (!this.select) return false
      const select = this.getSelectUrl(this.select)
      return select.type !== 'table' && select.type !== 'tableGroup' && select.type !== 'cardFormTable'
    },
    renderExtra() {
      const handleChange = (value) => {
        putAction('/farmKingKongTemp/update?id=' + value).then((res) => {
          if (res.code == 200) {
            this.$message.success('设置成功')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
      return (
        <div>
          <span>数量&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Select defaultValue={this.isShowNum} style={{ width: '70px' }} onChange={(value) => handleChange(value)}>
            {this.isShowNumList.map((e) => {
              return <Option value={e.id}>{e.displayNum}</Option>
            })}
          </Select>
        </div>
      )
    },
    renderCardView(view) {
      if (this.current_selection === 'kingkong') {
        return (
          <Card title={this.select.title} extra={this.renderExtra()}>
            {this.renderView(view)}
          </Card>
        )
      } else {
        return <Card title={this.select.title}>{this.renderView(view)}</Card>
      }
    },
    renderRight() {
      if (!this.select) return null
      const view = this.getFormData()
      console.log('---view---',view)
      return (
        <Col class="c-box-custom" span={20}>
          {view.type == 'tableGroup' ? this.renderView(view) : this.renderCardView(view)}
          {this.getShowFoot() && this.renderFoot()}
        </Col>
      )
    },
  },
  render() {
    return (
      <Row gutter={30}>
        {this.renderTree()}
        {this.renderRight()}
      </Row>
    )
  },
  renderError(h, error) {
    console.log('报错', error)
  },
}
</script>
<style lang="less" scoped>
.base-info-foot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  background: white;
}

.c-box-custom {
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-tree {
  height: 76.4vh !important;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
